import React from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'

export interface Props<T> {
  options?: {value: T; label: string | React.ReactNode}[]
  value: T
  onChange: (value: T) => void
  itemClassName?: string
}

export default function Toggle<T = string>({
  options = [],
  value: currValue,
  onChange,
  itemClassName
}: Props<T>) {
  const currSelectedIndex = options.findIndex(({value}) => value === currValue)
  const isDarkMode = useIsDarkMode()
  return (
    <div
      className={classnames({
        'flex rounded-full relative overflow-hidden': true,
        'bg-gray-100': !isDarkMode,
        'bg-zinc-500 text-gray-300': isDarkMode
      })}>
      <div
        style={{
          transform: `translateX(${currSelectedIndex * 100}%)`,
          width: `${100 / options.length}%`,
          zIndex: 0
        }}
        className="absolute h-full button-v3-primary rounded-full transition ease-out duration-150"
      />
      {options.map(({value, label}) => (
        <div
          onClick={() => {
            if (value === currValue) return
            onChange(value)
          }}
          key={`toggle_${value}`}
          style={{zIndex: 1}}
          className={classnames({
            [itemClassName]: !!itemClassName,
            'text-sm font-semibold flex flex-1 items-center justify-center': true,
            'px-1 py-3 rounded-full': true,
            'transition ease-in-out duration-150': true,
            'cursor-pointer': value !== currValue,
            'text-white bg-opacity-0 cursor-not-allowed': value === currValue
          })}>
          {label}
        </div>
      ))}
    </div>
  )
}
