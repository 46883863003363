import React, {Fragment} from 'react'
import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import {Dialog, Transition} from '@headlessui/react'
import classnames from '@helpers/misc/classnames'
import isServerSide from '@helpers/misc/isServerSide'
import useIsDarkMode from '@hooks/useIsDarkMode'

import Header from './Header'

import styles from './styles.module.css'
// const inter = Inter({subsets: ['latin']})

export interface ModalProps {
  children?: React.ReactNode
  header?: string | React.ReactNode
  isOpen?: boolean
  showClose?: boolean
  onClose?: () => void
  initialFocusRef?: React.RefObject<HTMLElement>
  headerDivider?: boolean
  footer?: React.ReactNode
  popFromBottom?: boolean
  hasHeightLimit?: boolean
  forceJustoFont?: boolean
  compact?: boolean
  className?: string
  canClickOutside?: boolean
  highZIndex?: boolean
}

const shouldPreventOnClickOutside = canClickOutside => {
  if (isServerSide()) return false
  if (!canClickOutside) return true
  if (document.getElementById('popup_modal_open')) {
    // This is hacky, but couldn't think of a better way to prevent the click outside when there are two modals open
    return true
  }

  return false
}

export default function Modal(props: ModalProps) {
  const {
    header,
    children,
    isOpen = true,
    onClose = () => {},
    showClose = true,
    initialFocusRef,
    headerDivider,
    footer,
    popFromBottom = true,
    hasHeightLimit = false,
    forceJustoFont = false,
    compact = false,
    canClickOutside = true,
    highZIndex = false
  } = props

  const isDarkMode = useIsDarkMode()
  const zIndex = highZIndex ? ' z-[110000]' : 'z-[1100]'

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classnames('relative', zIndex)}
        initialFocus={initialFocusRef}
        onClose={() => {
          if (!shouldPreventOnClickOutside(canClickOutside)) {
            onClose()
          }
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className={classnames('fixed inset-0 overflow-y-auto', {dark: isDarkMode}, zIndex)}>
          <div
            className={classnames(
              'flex sm:items-center justify-center min-h-[100dvh] text-center p-0',
              {
                'items-end': popFromBottom,
                'items-center': !popFromBottom
              }
            )}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-1100"
              enterFrom="opacity-0 translate-y-8 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-8 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={classnames(styles.modal, {
                  'relative rounded-t-lg md:rounded-lg text-left shadow-xl transform transition-all sm:my-8 w-full text-black':
                    true,
                  'sm:max-w-lg': !compact,
                  'sm:max-w-md': compact,
                  'bg-white text-black': !isDarkMode,
                  'bg-zinc-800 text-white': isDarkMode,
                  'rounded-lg mx-4 max-w-[90vw]': !popFromBottom,
                  'font-sans': forceJustoFont,
                  [props.className]: !!props.className
                })}>
                <div
                  id={`v3_modal`}
                  className={classnames({
                    'px-4 md:px-5': !compact,
                    'pt-5 md:pt-6': true,
                    'pb-5 md:pb-6': !footer,
                    'pb-0 md:pb-0': !!footer
                  })}>
                  <div
                    className={classnames({
                      'px-4 md:px-5': compact
                    })}>
                    <Header
                      header={header}
                      onClose={onClose}
                      showClose={showClose}
                      headerDivider={headerDivider}
                    />
                  </div>
                  <div
                    className={
                      hasHeightLimit
                        ? classnames({
                            'overflow-y-auto': true,
                            'max-h-[80vh]': !footer,
                            'max-h-[70vh]': !!footer
                          })
                        : ''
                    }>
                    <SafeSuspenseLoading>{children}</SafeSuspenseLoading>
                  </div>
                </div>
                {footer ? <div className="">{footer}</div> : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
