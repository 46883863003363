import useWebsiteId from '@hooks/useWebsiteId'
import {gql, useMutate} from 'apollo-hooks'

export default function useSetStoreIdInPreferences() {
  const websiteId = useWebsiteId()
  const mutate = useMutate()

  return (storeId: string) => {
    return mutate({
      mutation: gql`
        mutation setStoreIdInPreferences($websiteId: ID, $storeId: ID) {
          setUserPreferences(websiteId: $websiteId, storeId: $storeId) {
            _id
            storeId
            menuId
            store {
              minimumAnticipationDays
              availableScheduleDaysOptions {
                label
                value
              }
              schedule {
                itsOpenNow: itsAvailableAt(websiteId: $websiteId)
              }
            }
          }
        }
      `,
      variables: {storeId, websiteId}
    })
  }
}
