import React, {ReactNode} from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'

import Label from '../Label'

export interface Props<T = string> {
  options: {value: T; label: ReactNode | string; bottomLabel?: ReactNode}[]
  value: T
  onChange: (value: T) => void
  label?: string
}

export default function Radio(props: Props) {
  const isDarkMode = useIsDarkMode()
  return (
    <div className="flex flex-col">
      <Label>{props.label}</Label>
      {props.options.map(({value, label, bottomLabel}) => {
        const onClick = () => props.onChange(value)
        return (
          <div
            key={value}
            className={classnames({
              'border rounded-md mb-3': true,
              'shadow-lg border-gray-200 shadow-gray-200': !isDarkMode && props.value === value,
              'border-gray-100': !isDarkMode && props.value !== value,
              'shadow-lg border-zinc-400 shadow-zinc-700': isDarkMode && props.value === value,
              'border-zinc-700': isDarkMode && props.value !== value
            })}>
            <div
              className={classnames({
                'flex items-center justify-center px-3 py-4 cursor-pointer transition ease-in-out duration-150':
                  true,
                'text-gray-900': !isDarkMode && value === props.value,
                'text-white': isDarkMode && value === props.value
              })}
              onClick={onClick}>
              <div className="p-2 flex flex-col items-center">
                <input
                  type="radio"
                  value={value}
                  checked={value === props.value}
                  onChange={onClick}
                />
              </div>
              <div
                className={classnames({
                  'flex-1 text-sm': true,
                  'text-gray-600': !isDarkMode && value !== props.value,
                  'text-zinc-400': isDarkMode && value !== props.value
                })}>
                {label}
              </div>
            </div>
            {bottomLabel && props.value === value ? (
              <div
                className={classnames({
                  'border-b ml-4 mr-4': true,
                  'border-b-gray-100 ': !isDarkMode,
                  'border-b-zinc-700': isDarkMode
                })}></div>
            ) : null}
            {bottomLabel && props.value === value ? bottomLabel : null}
          </div>
        )
      })}
    </div>
  )
}
