import React from 'react'
import SuspenseLoading from '@components/SuspenseLoading'
import useScreenSize from '@hooks/useScreenSize'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import {useTranslation} from 'next-i18next'
import {Field} from 'simple-react-form'

import useTimeOptions from './hooks/useTimeOptions.main'

export default function HourField(props: {days: number}) {
  const {t} = useTranslation('website', {keyPrefix: 'schedule'})
  const screenSize = useScreenSize()
  const {preferences, networkStatus} = useTimeOptions(props.days)

  let timeOptions = []

  if (preferences?.timeOptions) {
    const queryOptions = preferences.timeOptions.map(option => ({
      ...option,
      isDisabled: option.disabled
    }))

    const isAnyOptionEnabled = queryOptions.some(option => !option.disabled)
    const options = isAnyOptionEnabled ? queryOptions : []

    timeOptions = options
  }

  if (timeOptions?.length === 0 && networkStatus === 7) {
    return <p className="text-red-800 font-semibold">Este día no tiene horarios disponibles</p>
  } else if (networkStatus !== 7) {
    return <SuspenseLoading />
  }

  return (
    <Field
      fieldName="time"
      placeholder={t('selectTime')}
      noOptionsMessage={() => t('closedThisDay')}
      type={Select}
      options={timeOptions}
      passProps={{
        menuPlacement: screenSize === 'mobile' ? 'top' : 'bottom',
        styles: {
          option: (styles, {isDisabled}) => {
            return {
              ...styles,
              textDecoration: isDisabled ? 'line-through' : styles.textDecoration,
              cursor: isDisabled ? 'not-allowed' : 'default'
            }
          }
        }
      }}
    />
  )
}
