import React, {useState} from 'react'
import useWebsiteId from '@hooks/useWebsiteId'
import {useMutate} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'
import {Form} from 'simple-react-form'

import useDeliveryWizardStep from '../../hooks/useDeliveryWizardStep'
import usePreferencesForDelivery from '../../hooks/usePreferencesForDelivery.main'
import StoreSelector from '../Shared/StoreSelector'

import useStoresInCoverage from './useStoresInCoverage.main'

export default function ChangePreferredStore() {
  const stores = useStoresInCoverage()
  const {preferences} = usePreferencesForDelivery()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.delivery'})
  const [formState, setFormState] = useState<{storeId?: string}>({
    storeId: preferences.preferredDeliveryStoreId
  })
  const {setSubstep} = useDeliveryWizardStep()
  const mutate = useMutate()

  const websiteId = useWebsiteId()

  const setPreferredStoreId = (storeId: string) => {
    return mutate({
      mutation: gql`
        mutation setPreferredDeliveryStore($websiteId: ID, $preferredDeliveryStoreId: ID) {
          setUserPreferences(
            websiteId: $websiteId
            preferredDeliveryStoreId: $preferredDeliveryStoreId
          ) {
            _id
            preferredDeliveryStoreId
            store {
              _id
              name
              address {
                address
                addressLine2
              }
            }
          }
        }
      `,
      variables: {websiteId, preferredDeliveryStoreId: storeId}
    })
  }

  if (!stores?.length) {
    return null
  }

  if (stores.length <= 1) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-lg font-semibold">{t('changeStore')}</h2>
      <Form
        state={formState}
        onChange={newState => {
          setPreferredStoreId(newState.storeId)
            .then(result => {
              setSubstep(null)
            })
            .catch(error => {
              console.log('setStoreIdError', error)
            })
          setFormState(newState)
        }}>
        <StoreSelector deliveryType="delivery" stores={stores} />
      </Form>
    </div>
  )
}
