import {MdOutlineTwoWheeler} from 'react-icons/md'
import SafeSuspense from '@components/SafeSuspense'
import Button from '@components/V3/Button'
import Pill from '@components/V3/Pill'
import {useTranslation} from 'next-i18next'

import useDeliveryWizardStep from '../../hooks/useDeliveryWizardStep'
import usePreferencesForDelivery from '../../hooks/usePreferencesForDelivery.main'

import ChangePreferredStore from './ChangePreferredStore'
import PreferredStoreInfo from './PreferredStoreInfo'

const addressStringParse = address => {
  return [address?.addressLine2, address?.address, address?.addressSecondary]
    .filter(v => !!v)
    .join(', ')
}

function DeliveryInner() {
  const {setStep, substep} = useDeliveryWizardStep()
  const {preferences} = usePreferencesForDelivery()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.delivery'})
  const {t: tGeneric} = useTranslation('generic', {keyPrefix: 'fields'})
  return (
    <div>
      {preferences.placeId ? (
        <>
          {substep === 'change-preferred-store' ? (
            <ChangePreferredStore />
          ) : (
            <>
              <div
                className="flex flew-row justify-between items-center min-w-0 cursor-pointer"
                onClick={() => setStep('create')}>
                <MdOutlineTwoWheeler className="w-10 mx-2" />
                <span className="text-ellipsis overflow-hidden whitespace-nowrap font-semibold text-sm">
                  {t('label')}:{' '}
                  <span className="font-normal">{addressStringParse(preferences.address)}</span>
                </span>
                <Pill className="text-xs ml-5" showCaret={false}>
                  {tGeneric('change')}
                </Pill>
              </div>
              <PreferredStoreInfo />
            </>
          )}
        </>
      ) : (
        <Button
          big={true}
          full={true}
          primary={true}
          className="rounded-xl"
          onClick={() => setStep('create')}>
          {t('addAddress')}
        </Button>
      )}
    </div>
  )
}

const Skeleton = () => (
  <div className="flex gap-4 animate-pulse">
    <div className="h-6 flex-1 bg-gray-300 dark:bg-zinc-600 rounded"></div>
    <div className="h-6 w-24 bg-gray-300 dark:bg-zinc-600 rounded"></div>
  </div>
)

export default function Delivery() {
  return (
    <SafeSuspense fallback={<Skeleton />}>
      <DeliveryInner />
    </SafeSuspense>
  )
}
