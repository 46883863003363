import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import dayjs from 'dayjs'
import gql from 'graphql-tag'

export default function useTimeOptions(days: number) {
  const date = dayjs()
    .add(days, 'days')
    .startOf('day')
    .add(3, 'hours') // to fix tz issues
    .format()

  const websiteId = useWebsiteId()

  const {preferences, loading, networkStatus} = useQuery({
    query: gql`
      query getTimeOptionsCheckoutV3($websiteId: ID, $date: Date) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          timeOptions(date: $date) {
            label
            value
            disabled
          }
        }
      }
    `,
    variables: {
      websiteId,
      date
    },
    fetchPolicy: 'network-only'
  })

  return {preferences, loading, networkStatus, date: dayjs().add(days, 'days').format('L')}
}
