import isStoreOpenNow from './isStoreOpenNow'

export default function canDeliverNow(store: {
  minimumAnticipationDays?: number
  schedule?: {itsOpenNow?: boolean}
}) {
  const noAnticipationNeeded = store && !store.minimumAnticipationDays
  const isOpenNow = isStoreOpenNow(store)
  const canDeliverNow = isOpenNow && noAnticipationNeeded

  return canDeliverNow
}
