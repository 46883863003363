import usePreferencesForAddressContext from './usePreferencesForAddressContext.main'

export default function useIsOutOfBounds() {
  const {preferences} = usePreferencesForAddressContext()

  if(!preferences?.placeId) return false

  if (preferences?.deliveryType === 'delivery' && !preferences?.storeId) {
    return true
  }

  return false
}
