import React from 'react'
import {MdKeyboardArrowDown as Caret} from 'react-icons/md'
import useIsDarkMode from '@hooks/useIsDarkMode'
import classnames from 'classnames'

import SpinnerLoading from '../loadingIndicators/Spinner'

export interface PillProps {
  children?: React.ReactNode
  onClick?: () => void
  outline?: boolean
  showCaret?: boolean
  caretDirection?: 'up' | 'down' | 'left' | 'right'
  square?: boolean
  big?: boolean
  small?: boolean
  primary?: boolean
  className?: string
  loading?: boolean
}

export default function Pill(props: PillProps) {
  const {
    className = '',
    onClick = () => {},
    children,
    outline,
    caretDirection = 'down',
    square = false,
    big = false,
    small = false,
    primary = false,
    showCaret = true,
    loading = false
  } = props

  const isDarkMode = useIsDarkMode()
  const style = outline ? 'outline' : primary ? 'primary' : isDarkMode ? 'dark' : 'default'

  return (
    <div
      className={classnames({
        'inline-flex text-sm border-2 items-center transition ease-in-out duration-150': true,
        '!text-xs h-8': small,
        'bg-gray-100 text-gray-700 border-gray-100': style === 'default',
        'bg-zinc-600 text-white border-zinc-600': style === 'dark',
        'cursor-pointer': !loading,
        'hover:bg-gray-200 hover:border-gray-200': !loading && style === 'default',
        'hover:bg-zinc-400 hover:border-zinc-400': !loading && style === 'dark',
        'bg-transparent border-gray-100': style === 'outline',
        'border-gray-600 !border': style === 'outline' && isDarkMode,
        'hover:bg-gray-100': !loading && style === 'outline',
        'button-v3-primary focus:button-v3-primary text-white border-0': style === 'primary',
        'rounded-full': !square,
        'rounded-md': square,
        'px-4': !!children,
        'px-[2px] py-0': !children,
        'py-0': !big && !!children,
        'py-2': big,
        [className]: true
      })}
      onClick={() => {
        if (loading) return
        onClick()
      }}>
      <div className="flex-1 font-semibold">
        {loading ? <SpinnerLoading className="text-gray-300" /> : children}
      </div>
      {showCaret ? (
        <div
          className={classnames({
            'flex items-start flex-col transition ease-in-out duration-300': true,
            'ml-1': !!children,
            'transform rotate-0': caretDirection === 'down',
            'transform rotate-180': caretDirection === 'up',
            'transform rotate-90': caretDirection === 'left',
            'transform rotate-[-90deg]': caretDirection === 'right'
          })}>
          <div className="h-[2px]"></div>
          <Caret size={small ? 20 : 24} />
        </div>
      ) : null}
    </div>
  )
}
