import {
  PreferencesForScheduleFormQuery,
  PreferencesForScheduleFormQueryVariables,
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useSchedulePreferences() {
  const websiteId = useWebsiteId()
  const {preferences} = useQuery<
    PreferencesForScheduleFormQuery,
    PreferencesForScheduleFormQueryVariables
  >({
    query: gql`
      query preferencesForScheduleForm($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          menuId
          deliverAt
          deliveryType
          time
          dropOffType
          store {
            _id
            availableScheduleDaysOptions {
              label
              value
            }
            schedule {
              itsOpenNow: itsAvailableAt(websiteId: $websiteId)
            }
            minimumAnticipationDays
            disableNowOrders
          }
        }
      }
    `,
    variables: {
      websiteId,
    },
  })

  return preferences
}
