import React from 'react'
import {MdLocationPin, MdShoppingBag} from 'react-icons/md'
import SpinnerLoading from '@components/V3/loadingIndicators/Spinner'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import useDeliveryWizardStep from '../../hooks/useDeliveryWizardStep'
import usePreferencesForAddressContext from '../../hooks/usePreferencesForAddressContext.main'
import useSetDeliveryTypeInPreferences from '../../hooks/useSetDeliveryTypeInPreferences.main'

export default function OutOfBounds() {
  const {setDeliveryTypeInPreferences, loading} = useSetDeliveryTypeInPreferences()
  const {setStep} = useDeliveryWizardStep()
  const {preferences} = usePreferencesForAddressContext()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.outOfBoundsDetails'})
  const {website} = useInitialData()
  const hasTakeout = website.deliveryTypes.includes('go')

  return (
    <div className="bg-white dark:bg-zinc-800 p-4 flex flex-col gap-2">
      <h2 className="text-lg font-bold">{t('title')}</h2>

      <p className="text-gray-600 dark:text-gray-300 text-base">
        {preferences?.address?.address ? (
          <span className="italic text-gray-500 dark:text-gray-400">
            {' '}
            {preferences.address.address}
          </span>
        ) : (
          <span>{t('submittedAddress')}</span>
        )}{' '}
        {t('isOutOfBounds')}
      </p>
      <div className="flex items-center py-2 mt-2">
        <div className="flex-1 border-gray-200 border-t dark:border-zinc-600"></div>
        <p className="text-sm px-4 text-gray-600 dark:text-gray-400">{t('options.title')}</p>
        <div className="flex-1 border-gray-200 border-t dark:border-zinc-600"></div>
      </div>
      <div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-600 text-sm font-semibold cursor-pointer">
        {loading ? (
          <div className="flex justify-center py-2">
            <SpinnerLoading size="lg" />
          </div>
        ) : (
          <>
            {hasTakeout ? (
              <div
                className="flex gap-2 items-center p-4 transition-all duration-200 hover:bg-gray-200 dark:hover:bg-gray-600"
                onClick={() => setDeliveryTypeInPreferences('go')}>
                <MdShoppingBag className="w-5 h-5" />
                <span>{t('options.takeout')}</span>
              </div>
            ) : null}
            <div
              className="flex gap-2 items-center p-4 transition-all duration-200 hover:bg-gray-200 dark:hover:bg-gray-600"
              onClick={() => setStep('create')}>
              <MdLocationPin className="w-5 h-5" />
              <span>{t('options.changeAddress')}</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
