import React from 'react'

export default function Label({
  children,
  className = ''
}: {
  children: React.ReactNode
  className?: string
}) {
  return <div className={`text-sm font-medium py-1 mt-2 ${className}`}>{children}</div>
}
