import {
  GetPreferencesForDeliveryQuery,
  GetPreferencesForDeliveryQueryVariables
} from '@data/__generated__/types.main'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function usePreferencesForDelivery() {
  const websiteId = useWebsiteId()
  const result = useQuery<GetPreferencesForDeliveryQuery, GetPreferencesForDeliveryQueryVariables>({
    query: gql`
      query getPreferencesForDelivery($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          placeId
          storeId
          preferredDeliveryStoreId
          website {
            _id
            showStoreName
          }
          store {
            _id
            name
          }
          address {
            _id
            address
            addressSecondary
            addressLine2
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    omit: isServerSide()
  })

  return {
    preferences: result.preferences,
    loading: result.loading
  }
}
