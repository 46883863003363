import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {gql, useMutate} from 'apollo-hooks'
import {useTranslation} from 'next-i18next'

export default function useSetServePreferences() {
  const websiteId = useWebsiteId()
  const mutate = useMutate()
  const showMessage = useMessage()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.edit'})

  return ({storeId, tableName}: {storeId?: string; tableName?: string}) =>
    mutate({
      mutation: gql`
        mutation setServePreferences($websiteId: ID, $storeId: ID, $tableName: String) {
          setUserPreferences(websiteId: $websiteId, storeId: $storeId, tableName: $tableName) {
            _id
            tableName
            storeId
            store {
              minimumAnticipationDays
              availableScheduleDaysOptions {
                label
                value
              }
              schedule {
                itsOpenNow: itsAvailableAt(websiteId: $websiteId)
              }
            }
          }
        }
      `,
      variables: {storeId, websiteId, tableName}
    }).then(() => {
      // showMessage(t('success'))
    })
}
