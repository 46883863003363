import {StoresInCoverageQuery, StoresInCoverageQueryVariables} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import usePreferencesForDelivery from '../../hooks/usePreferencesForDelivery.main'

export default function useStoresInCoverage() {
  const websiteId = useWebsiteId()
  const {preferences} = usePreferencesForDelivery()

  const {stores} = useQuery<StoresInCoverageQuery, StoresInCoverageQueryVariables>({
    query: gql`
      query storesInCoverage($websiteId: ID, $placeId: ID) {
        stores: storesInCoverage(websiteId: $websiteId, placeId: $placeId) {
          _id
          name
          address {
            address
            addressLine2
          }
        }
      }
    `,
    variables: {
      websiteId,
      placeId: preferences.placeId
    }
  })

  return stores
}
