import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import ScheduleForm from '@components/ScheduleForm'
import {useTranslation} from 'next-i18next'

export interface Props {
  onClose?: () => void
}

export default function Schedule(props: Props) {
  const {t} = useTranslation('website', {keyPrefix: 'delivery.schedule'})
  return (
    <SafeSuspenseLoading>
      <h2 className="mb-5 text-xl">{t('title')}</h2>
      <ScheduleForm formLocation="delivery-wizard" onClose={props.onClose} autosave />
    </SafeSuspenseLoading>
  )
}
