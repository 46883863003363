import Radio from '@components/V3/forms/Radio'
import {Field} from 'simple-react-form'

import useStores from '../../hooks/useStores.main'

export interface Props {
  deliveryType: string
  stores?: Array<{_id?: string; address?: {address?: string; addressLine2?: string}}>
}

export default function StoreSelector({deliveryType, stores}: Props) {
  const {storeTo} = useStores()
  const options = (stores ? stores : storeTo(deliveryType)).map(store => ({
    label: (
      <p className="text-gray-900 dark:text-white text-md font-semibold">
        {store.name}
        <span className="text-gray-600 dark:text-gray-300 ml-2 font-normal">
          {store.address.address} {store.address.addressLine2}
        </span>
      </p>
    ),
    value: store._id
  }))

  return (
    <div className="max-h-[380px] overflow-auto">
      <Field fieldName="storeId" type={Radio} options={options} />
    </div>
  )
}
