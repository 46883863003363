import React, {useState} from 'react'
import ChooseTimeAndPlace from '@components/DeliveryWizard/internals/ChooseTimeAndPlace'
import {
  DeliveryWizardStep,
  DeliveryWizardStepContext,
  DeliveryWizardSubstep
} from '@components/DeliveryWizard/internals/hooks/useDeliveryWizardStep'
import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import Modal from '@components/V3/Modal'

import AddressSelector from '../internals/AddressSelector'

export default function DeliveryWizardComponent() {
  const [step, setStep] = useState<DeliveryWizardStep>('choose')
  const [substep, setSubstep] = useState<DeliveryWizardSubstep>('search')
  return (
    <div className="rounded-xl py-1 shadow-md border-gray-100 bg-white dark:bg-zinc-800 dark:border-zinc-800 border-2 my-2 transition-all font-sans">
      <SafeSuspenseLoading>
        <DeliveryWizardStepContext.Provider
          value={{
            setStep,
            step,
            setSubstep,
            substep
          }}>
          <div className="bg-gray-100 dark:bg-zinc-700">
            <ChooseTimeAndPlace />
          </div>
          <Modal
            onClose={() => setStep('choose')}
            isOpen={step === 'create'}
            compact
            className="dark:bg-zinc-800 font-sans">
            <div className="bg-gray-100 dark:bg-zinc-700">
              <AddressSelector />
            </div>
          </Modal>
        </DeliveryWizardStepContext.Provider>
      </SafeSuspenseLoading>
    </div>
  )
}
