import React, {ReactNode} from 'react'
import {MdClose} from 'react-icons/md'
import {Dialog} from '@headlessui/react'

export default function Header({
  header,
  onClose,
  showClose,
  headerDivider
}: {
  header: string | ReactNode
  onClose: () => void
  showClose: boolean
  headerDivider?: boolean
}) {
  return (
    <div className="flex flex-col dark:text-white">
      <div className="flex text-lg font-semibold mb-2">
        <Dialog.Title as="h2" className="flex-1">
          {header}
        </Dialog.Title>
        {showClose ? (
          <button className="cursor-pointer" aria-label="Close button" onClick={onClose}>
            <MdClose size={24} />
          </button>
        ) : null}
      </div>
      {headerDivider ? (
        <div className="mt-4 border-b border-b-gray-100 dark:border-zinc-400" />
      ) : null}
    </div>
  )
}
