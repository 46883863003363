import React from 'react'
import {useTranslation} from 'next-i18next'

import useDeliveryWizardStep from '../../hooks/useDeliveryWizardStep'
import usePreferencesForDelivery from '../../hooks/usePreferencesForDelivery.main'

import useStoresInCoverage from './useStoresInCoverage.main'

export default function PreferredStoreInfo() {
  const {preferences} = usePreferencesForDelivery()
  const stores = useStoresInCoverage()

  const {t} = useTranslation('website', {keyPrefix: 'delivery.delivery'})
  const {t: tGeneric} = useTranslation('generic', {keyPrefix: 'fields'})
  const {setSubstep} = useDeliveryWizardStep()

  if (
    preferences?.website?.showStoreName !== true ||
    !preferences?.storeId ||
    !stores?.length ||
    stores.length <= 1
  ) {
    return null
  }

  return (
    <div className="text-sm p-2 text-gray-500 dark:text-gray-400">
      <span>{t('currentStore', {storeName: preferences.store?.name})} </span>
      <a className="underline cursor-pointer" onClick={() => setSubstep('change-preferred-store')}>
        {tGeneric('change')}
      </a>
    </div>
  )
}
