import {useState} from 'react'
import SafeSuspense from '@components/SafeSuspense'
import {Form} from 'simple-react-form'

import usePreferencesForAddressContext from '../hooks/usePreferencesForAddressContext.main'
import useSetStoreIdInPreferences from '../hooks/useStoreIdInPreferences'

import SubcomponentSkeleton from './components/SubcomponentSkeleton'
import StoreSelector from './Shared/StoreSelector'

function GoInner() {
  const {preferences} = usePreferencesForAddressContext()
  const setStoreId = useSetStoreIdInPreferences()
  const [formState, setFormState] = useState<{storeId?: string}>({
    storeId: preferences.storeId
  })

  return (
    <div>
      <Form
        state={formState}
        onChange={newState => {
          setStoreId(newState.storeId)
            .then(result => {})
            .catch(error => {
              console.log('setStoreIdError', error)
            })
          setFormState(newState)
        }}>
        <StoreSelector deliveryType="go" />
      </Form>
    </div>
  )
}

export default function Go() {
  return (
    <SafeSuspense fallback={<SubcomponentSkeleton />}>
      <GoInner />
    </SafeSuspense>
  )
}
