import {useEffect, useState} from 'react'
import {MdOutlineRestaurant, MdOutlineShoppingBag, MdOutlineTwoWheeler} from 'react-icons/md'
import SuspenseLoading from '@components/SuspenseLoading'
import Toggle from '@components/V3/Toggle'
import canSchedule from '@helpers/websites/canSchedule'
import useInitialData from '@page-components/Layout/useInitialData'
import {useTranslation} from 'next-i18next'

import useIsOutOfBounds from '../hooks/useIsOutOfBounds'
import usePreferencesForAddressContext from '../hooks/usePreferencesForAddressContext.main'
import useSetDeliveryTypeInPreferences from '../hooks/useSetDeliveryTypeInPreferences.main'

import SubcomponentSkeleton from './components/SubcomponentSkeleton'
import OutOfBounds from './Delivery/OutOfBounds'
import AddressCard from './Shared/AddressCard'
import ToggleLabel from './Shared/ToggleLabel'
import Delivery from './Delivery'
import Go from './Go'
import Schedule from './Schedule'
import Serve from './Serve'

const componentMap = {
  delivery: Delivery,
  serve: Serve,
  go: Go
}

export interface Props {
  onSave?: () => void
  hideScheduleOptions?: boolean
}

export default function ChooseTimeAndPlace(props: Props) {
  const {t} = useTranslation('generic', {keyPrefix: 'fields.deliveryType'})
  const {t: tDelivery} = useTranslation('website', {keyPrefix: 'delivery'})
  const {preferences} = usePreferencesForAddressContext()
  const {setDeliveryTypeInPreferences, loading} = useSetDeliveryTypeInPreferences()
  const [deliveryTypeBuffer, setDeliveryTypeBuffer] = useState(preferences.deliveryType)
  const {website} = useInitialData()

  useEffect(() => {
    setDeliveryTypeBuffer(preferences.deliveryType)
  }, [preferences.deliveryType])

  const isOutOfBounds = useIsOutOfBounds()
  const labels = {
    delivery: {Icon: MdOutlineTwoWheeler, text: t('delivery')},
    go: {Icon: MdOutlineShoppingBag, text: 'Retirar'},
    serve: {Icon: MdOutlineRestaurant, text: t('serve')}
  }

  if (isOutOfBounds) {
    return <OutOfBounds />
  }

  const showScheduleOptions =
    preferences.deliveryType !== 'serve' &&
    preferences.storeId &&
    canSchedule(preferences.store) &&
    props.hideScheduleOptions !== true

  const Subcomponent = componentMap[preferences.deliveryType]

  return (
    <div className="flex flex-col gap-4">
      <div>
        <AddressCard shadow={false}>
          <h2 className="text-xl font-bold mb-5">{tDelivery('title')}</h2>
          <Toggle
            itemClassName="!px-0 md:px-5"
            options={Object.keys(labels)
              .filter(key => website.deliveryTypes.includes(key) && labels[key])
              .map(key => ({
                label: <ToggleLabel {...labels[key]} />,
                value: key
              }))}
            onChange={value => {
              setDeliveryTypeBuffer(value)
              setDeliveryTypeInPreferences(value)
                .then(result => {})
                .catch(error => {
                  setDeliveryTypeBuffer(preferences.deliveryType)
                })
            }}
            value={deliveryTypeBuffer}
          />
        </AddressCard>
        <AddressCard shadow={showScheduleOptions}>
          {loading ? (
            <SubcomponentSkeleton />
          ) : (
            <SuspenseLoading>
              <Subcomponent />
            </SuspenseLoading>
          )}
        </AddressCard>
      </div>
      {showScheduleOptions && (
        <AddressCard shadow={false}>
          <Schedule onClose={props.onSave} />
        </AddressCard>
      )}
    </div>
  )
}
