import SuspenseLoading from '@components/SuspenseLoading'
import Button from '@components/V3/Button'
import Toggle from '@components/V3/Toggle'
import {EVENTS} from '@helpers/analytics/events'
import {BrowseTaxonomies} from '@helpers/analytics/events/browse'
import {sendEvent} from '@helpers/analytics/sendEvents'
import dropOffTypeLabels from '@helpers/labels/dropOffTypeLabels'
import canSchedule from '@helpers/websites/canSchedule'
import {getTimeValue, isValidTime} from '@helpers/websites/scheduleHelpers'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useMessage from '@hooks/useMessage'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import isNil from 'lodash/isNil'
import {useTranslation} from 'next-i18next'
import {ReactNode, useEffect, useRef, useState} from 'react'
import {MdListAlt} from 'react-icons/md'
import {Field, Form} from 'simple-react-form'
import HourField from './HourField'
import useScheduleOptions from './hooks/useScheduleOptions'
import useSchedulePreferences from './hooks/useSchedulePreferences.main'
import useTimeOptions from './hooks/useTimeOptions.main'

export interface Props {
  onClose?: () => void
  soonestAvailableLabel?: string | ReactNode
  showDropOffSection?: boolean
  onChangeTimePreferences?: () => Promise<void>
  className?: string
  autosave?: boolean
  showSuccessMessage?: boolean
  formLocation?: string
}

export default function ScheduleForm(props: Readonly<Props>) {
  const {t} = useTranslation('website', {keyPrefix: 'schedule'})
  const {t: tGeneric} = useTranslation('generic')
  const preferences = useSchedulePreferences()
  const timeOptions = useScheduleOptions()
  const showMessage = useMessage()
  const eventBaseProperties = useEventsBaseProperties()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const analyticsV2 = useLazyAnalyticsV2()

  const [submitting, setSubmitting] = useState(false)
  const [formState, setFormState] = useState<{days?: number; time?: string; dropOffType?: string}>({
    days: getTimeValue(preferences.time).days,
    time: getTimeValue(preferences.time).time,
    dropOffType: preferences.dropOffType,
  })
  const {preferences: preferencesTime, date} = useTimeOptions(formState.days ?? 0)

  const time = timeOptions.deliverNow
    ? 'now'
    : !isNil(formState.days) && !isNil(formState.time)
      ? `${formState.time}+${formState.days}`
      : undefined

  const isMounted = useRef(false)
  useEffect(() => {
    // We don't want to submit on the first mount.
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    if (!props.autosave) return

    if (isValidTime(time, formState.days)) {
      onSubmit()
    }
  }, [time, formState.days])

  const onSubmit = async () => {
    if (!time) return
    if (!isValidTime(time, formState.days)) {
      showMessage(t('errors.invalidTime'))
      return
    }

    setSubmitting(true)

    try {
      await timeOptions.savePreferences({
        time,
        dropOffType: formState.dropOffType,
      })
      const scheduleTime = timeOptions?.deliverNow
        ? ''
        : preferencesTime?.timeOptions?.find(option => option.value === formState.time)?.label
      if (!isAnalyticsV2Avail) {
        sendEvent<BrowseTaxonomies['deliveryTypeScheduled']>(EVENTS.browse.deliveryTypeScheduled, {
          scheduleAction: timeOptions?.deliverNow ? 'now' : 'schedule',
          scheduleDate: date ?? '',
          scheduleTime: scheduleTime ?? '',
          isStoreOpen: preferences?.store?.schedule?.itsOpenNow,
          isOutOfStock: false,
          menuId: preferences?.menuId ?? '',
          actionLocation: props.formLocation,
          ...eventBaseProperties,
        })
      } else {
        analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.deliveryTypeScheduled, {
          scheduleAction: timeOptions?.deliverNow ? 'now' : 'schedule',
          scheduleDate: date ?? '',
          scheduleTime: scheduleTime ?? '',
          isStoreOpen: preferences?.store?.schedule?.itsOpenNow,
          isOutOfStock: false,
          menuId: preferences?.menuId ?? '',
          actionLocation: props.formLocation,
        })
      }
      if (props.showSuccessMessage) {
        showMessage(t('updated'))
      }
      await props.onChangeTimePreferences?.()
      props.onClose?.()
    } catch (error) {
      showMessage(error)
    } finally {
      setSubmitting(false)
    }
  }

  const renderToggle = () => {
    if (!canSchedule(preferences.store)) return null
    return (
      <div className={props.className}>
        <Toggle
          onChange={timeOptions.setDeliverNow}
          options={timeOptions.options}
          value={timeOptions.deliverNow}
        />
      </div>
    )
  }

  const renderFormContent = () => {
    return timeOptions.deliverNow ? (
      <>
        {props.soonestAvailableLabel && (
          <div className="py-4 text-center">
            <SuspenseLoading>{props.soonestAvailableLabel}</SuspenseLoading>
          </div>
        )}
      </>
    ) : (
      <>
        <Field
          fieldName="days"
          placeholder={t('selectDay')}
          type={Select}
          options={preferences.store.availableScheduleDaysOptions}
        />
        <SuspenseLoading height={40}>
          {formState.days === undefined ? null : <HourField days={formState.days} />}
        </SuspenseLoading>
      </>
    )
  }

  const renderDropOffSection = () => {
    if (preferences.deliveryType !== 'delivery' || !props.showDropOffSection) return null
    return (
      <>
        <div className="font-medium text-sm my-2">
          <MdListAlt size={16} className="mr-1" />
          {t('dropOffType')}
        </div>
        <Field
          fieldName="dropOffType"
          type={Select}
          isSearchable={false}
          options={Object.keys(dropOffTypeLabels).map(key => ({
            label: dropOffTypeLabels[key],
            value: key,
          }))}
        />
      </>
    )
  }

  const renderSaveButton = () => {
    if (props.autosave) return null
    return (
      <Button
        full
        big
        primary
        onClick={onSubmit}
        disabled={submitting || !time}
        loading={submitting}
      >
        {submitting ? null : tGeneric('fields.save')}
      </Button>
    )
  }

  if (timeOptions.error) {
    return (
      <div className={props.className}>
        {renderToggle()}
        <p className="text-red">{timeOptions.error}</p>
      </div>
    )
  }

  return (
    <Form state={formState} onChange={setFormState}>
      {renderToggle()}
      {renderFormContent()}
      {renderDropOffSection()}
      {renderSaveButton()}
    </Form>
  )
}
