import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import Button from '@components/V3/Button'
import Modal from '@components/V3/Modal'
import {EVENTS} from '@helpers/analytics/events'
import {BrowseTaxonomies} from '@helpers/analytics/events/browse'
import {sendEvent as sendAmplitudeEvent} from '@helpers/analytics/sendEvents'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '@page-components/AnalyticsV2/types/analyticsServicesTypes'
import {useTranslation} from 'next-i18next'
import {useEffect, useState} from 'react'
import AddressSelector from '../internals/AddressSelector'
import ChooseTimeAndPlace from '../internals/ChooseTimeAndPlace'
import {
  DeliveryWizardStepContext,
  DeliveryWizardSubstep,
} from '../internals/hooks/useDeliveryWizardStep'
import usePreferencesForAddressContext from '../internals/hooks/usePreferencesForAddressContext.main'

const SELECTION_TYPES = {
  'choose:select': 'saved selection',
  'create:search': 'suggested',
}

interface CreateAddressProps {
  onClose: () => void
  isOpen: boolean
  initialStep?: 'choose' | 'create'
  hideScheduleOptions?: boolean
  actionLocation?: string
  highZIndex?: boolean
  canClickOutside?: boolean
  onChange?: (place: any) => void
}

const sendEvent = ({preferences, step, substep, actionLocation}, baseProperties) => {
  sendAmplitudeEvent<BrowseTaxonomies['addressSubmitted']>(EVENTS.browse.addressSubmitted, {
    selectionType: SELECTION_TYPES[`${step}:${substep}`] || 'manual',
    isPinAdjusted: false,
    deliveryType: preferences?.deliveryType,
    isAddressCoverage: preferences?.deliveryType !== 'delivery' || !!preferences?.storeId,
    isStoreOpen: !!preferences?.store?.schedule?.itsOpenNow,
    errorMessage: '',
    actionLocation,
    menuId: preferences?.menuId,
    ...baseProperties,
  })
}

export default function DeliveryWizardModal({
  onClose,
  isOpen,
  initialStep = 'choose',
  hideScheduleOptions,
  actionLocation = '',
  canClickOutside = true,
  highZIndex = false,
  onChange,
}: CreateAddressProps) {
  const [step, setStep] = useState(initialStep)
  const {t} = useTranslation('generic', {keyPrefix: 'fields'})
  const [substep, setSubstep] = useState<DeliveryWizardSubstep>('select')
  const {preferences} = usePreferencesForAddressContext()
  const eventBaseProperties = useEventsBaseProperties()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const analyticsV2 = useLazyAnalyticsV2()

  const disabled =
    preferences?.deliveryType === 'delivery'
      ? !preferences?.address?.address
      : preferences?.deliveryType === 'serve'
        ? !preferences?.tableName || !preferences?.storeId
        : !preferences?.storeId

  const onClick = () => {
    onClose()
    if (!isAnalyticsV2Avail) {
      sendEvent({preferences, step, substep, actionLocation}, eventBaseProperties)
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.addressSubmitted, {
        selectionType: SELECTION_TYPES[`${step}:${substep}`] || 'manual',
        isPinAdjusted: false,
        deliveryType: preferences?.deliveryType,
        isAddressCoverage: preferences?.deliveryType !== 'delivery' || !!preferences?.storeId,
        isStoreOpen: !!preferences?.store?.schedule?.itsOpenNow,
        actionLocation,
        menuId: preferences?.menuId,
      })
    }
  }

  useEffect(() => {
    if (!isOpen || !preferences?._id) return
    if (!isAnalyticsV2Avail) {
      sendAmplitudeEvent<BrowseTaxonomies['addressModalViewed']>(EVENTS.browse.addressModalViewed, {
        actionLocation,
        menuId: preferences?.menuId ?? '',
        ...eventBaseProperties,
      })
    } else {
      analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.addressModalViewed, {
        actionLocation,
        menuId: preferences?.menuId ?? '',
      })
    }
  }, [isOpen, preferences?._id])

  return (
    <DeliveryWizardStepContext.Provider
      value={{
        setStep,
        step,
        setSubstep,
        substep,
        onClose,
      }}
    >
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        compact
        canClickOutside={canClickOutside}
        highZIndex={highZIndex}
        className="dark:bg-zinc-800 font-sans"
        footer={
          step === 'create' ? null : (
            <div className="p-4">
              <Button primary full big onClick={onClick} disabled={disabled}>
                {t('confirm')}
              </Button>
            </div>
          )
        }
      >
        <SafeSuspenseLoading>
          <div className="bg-gray-100 dark:bg-zinc-800">
            {step === 'choose' ? (
              <ChooseTimeAndPlace hideScheduleOptions={hideScheduleOptions} />
            ) : (
              <AddressSelector onChange={onChange} />
            )}
          </div>
        </SafeSuspenseLoading>
      </Modal>
    </DeliveryWizardStepContext.Provider>
  )
}
