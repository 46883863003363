import dayjs from '@helpers/dayjs'
import {Preferences} from '@hooks/useTimeField'
import isNumber from 'lodash/isNumber'

export function isValidTime(time, maxDaysPreference) {
  try {
    const [timePart, daysPart] = time.split('+')

    if (!timePart || timePart === 'null' || daysPart === 'null') return false

    if (daysPart) {
      if (!isNumber(Number(daysPart))) return false
      if (Number(daysPart) > maxDaysPreference) return false
    }

    return true
  } catch (error) {
    console.log('Error:', error)

    return false
  }
}

export function getTimeValue(time) {
  if (!time || time === 'now') {
    return {
      days: '0',
      time: dayjs().format('HH:mm')
    }
  }

  const [timePart, datePart] = time.split('+')
  return {
    days: datePart || '0',
    time: timePart || dayjs().format('HH:mm')
  }
}

const SCHEDULING_PREFERENCE_MAX_INDEX = 46

export function getSchedulingDays(preferences: Preferences) {
  return (preferences?.store?.schedulingOrdersDays || SCHEDULING_PREFERENCE_MAX_INDEX) - 1
}
