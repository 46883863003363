import React from 'react'

export default function SubcomponentSkeleton() {
  return (
    <div className="flex flex-col animate-pulse py-4">
      <div className="border-4 mb-3 h-14 bg-gray-200 dark:bg-zinc-700 dark:border-zinc-700 rounded"></div>
      <div className="border-4 mb-3 h-14 bg-gray-200 dark:bg-zinc-700 dark:border-zinc-700 rounded"></div>
      <div className="border-4 mb-3 h-14 bg-gray-200 dark:bg-zinc-700 dark:border-zinc-700 rounded"></div>
    </div>
  )
}
