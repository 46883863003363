import {useState} from 'react'
import SafeSuspense from '@components/SafeSuspense'
import Button from '@components/V3/Button'
import Text from '@components/V3/forms/Text'
import logger from '@helpers/logger'
import {useTranslation} from 'next-i18next'
import {Field, Form} from 'simple-react-form'

import useDeliveryWizardStep from '../hooks/useDeliveryWizardStep'
import usePreferencesForAddressContext from '../hooks/usePreferencesForAddressContext.main'
import useSetServePreferences from '../hooks/useSetServePreferences'

import SubcomponentSkeleton from './components/SubcomponentSkeleton'
import StoreSelector from './Shared/StoreSelector'

function ServeInner() {
  const {preferences, loading} = usePreferencesForAddressContext()
  const setServePreferences = useSetServePreferences()
  const {onClose} = useDeliveryWizardStep()
  const {t} = useTranslation('website', {keyPrefix: 'delivery.serve'})

  const [formState, setFormState] = useState<{tableName?: string; storeId?: string}>({
    tableName: preferences.tableName,
    storeId: preferences.storeId
  })
  const changesOnTable =
    formState.tableName !== preferences.tableName || formState.storeId !== preferences.storeId
  return (
    <div>
      <Form state={formState} onChange={setFormState}>
        <StoreSelector deliveryType="serve" />
        <Field
          fieldName="tableName"
          type={Text}
          label={t('label')}
          placeholder={t('placeholder')}
        />
      </Form>
      {changesOnTable && (
        <Button
          className="mt-4"
          full={true}
          primary={true}
          onClick={() => {
            setServePreferences(formState)
              .then(result => {
                onClose?.()
              })
              .catch(error => {
                logger.warn('setServePreferencesError', error)
              })
          }}>
          Fijar mesa
        </Button>
      )}
    </div>
  )
}

export default function Serve() {
  return (
    <SafeSuspense fallback={<SubcomponentSkeleton />}>
      <ServeInner />
    </SafeSuspense>
  )
}
