import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export const getStoresQuery = gql`
  query getStores($websiteId: ID) {
    stores(websiteId: $websiteId) {
      items {
        _id
        name
        acceptServe
        acceptGo
        address {
          address
          addressLine2
          placeId
        }
      }
    }
  }
`

export default function useStores() {
  const websiteId = useWebsiteId()
  const {stores, loading, error} = useQuery({
    query: getStoresQuery,
    variables: {websiteId}
  })

  const storeTo = deliveryType =>
    stores?.items?.filter?.(store => {
      if (!store.address?.placeId) return false

      if (deliveryType === 'go' && store.acceptGo) {
        return true
      }
      if (deliveryType === 'serve' && store.acceptServe) {
        return true
      }

      return false
    })

  return {stores, storeTo, loading, error}
}
